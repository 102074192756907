/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ApprovalsService {
    http;
    constructor(http) {
        this.http = http;
    }
    create(orderId, data) {
        return this.http.post(`${environment.base}/approvals/${orderId}`, data);
    }
    getOrderApprovals(orderId) {
        return this.http.get(`${environment.base}/approvals/${orderId}`);
    }
    getApproval(approvalId) {
        return this.http.get(`${environment.base}/approvals/approval/${approvalId}`);
    }
    getApprovalByIdAndKey(approvalId, approvalKey) {
        return this.http.get(`${environment.base}/approvals/approval/${approvalId}/${approvalKey}`);
    }
    createApprovalPoint(approvalId, revisionId, key, point) {
        return this.http.post(`${environment.base}/approvals/point/${approvalId}/${revisionId}/${key}`, point);
    }
    deleteApprovalPoint(approvalId, key, pointId) {
        return this.http.delete(`${environment.base}/approvals/point/${approvalId}/${pointId}/${key}`);
    }
    requestChanges(approvalId, revisionId, approvalKey) {
        return this.http.put(`${environment.base}/approvals/request/${approvalId}/${revisionId}/${approvalKey}`, undefined);
    }
    approve(approvalId, revisionId, approvalKey) {
        return this.http.put(`${environment.base}/approvals/approve/${approvalId}/${revisionId}/${approvalKey}`, undefined);
    }
    download(approvalId, id, key) {
        return this.http.get(`${environment.base}/approvals/download/${approvalId}/${id}`, {
            headers: {
                'approvalKey': key,
            },
            responseType: 'arraybuffer',
            observe: 'response'
        })
            .pipe(map(resp => {
            return new Blob([resp.body], { type: resp.headers.get('content-type') });
        }));
    }
    static ɵfac = function ApprovalsService_Factory(t) { return new (t || ApprovalsService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ApprovalsService, factory: ApprovalsService.ɵfac });
}
