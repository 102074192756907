export var EComplaintState;
(function (EComplaintState) {
    EComplaintState["NEW"] = "new";
    EComplaintState["IN_PROGRESS"] = "in_progress";
    EComplaintState["SOLVED"] = "solved";
    EComplaintState["REJECTED"] = "rejected";
})(EComplaintState || (EComplaintState = {}));
export var EComplaintType;
(function (EComplaintType) {
    EComplaintType["COMPLAINT"] = "complaint";
    EComplaintType["INTERNAL"] = "internal";
})(EComplaintType || (EComplaintType = {}));
